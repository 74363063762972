import './Home.css';
import React, { useState } from 'react';

function Home() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="app">
            <div className="header">
                Feel free to contact me regarding any topic of common interest. For professional queries, please send me an email.
                <div className="social-links">
                    <a href="https://www.linkedin.com/in/ishavasya" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href="https://mail.google.com/mail/u/0/?fs=1&to=rotten.pantsnew@gmail.com&tf=cm" target="_blank" rel="noopener noreferrer">Gmail</a>
                    <a href="https://twitter.com/ishavasya3" target="_blank" rel="noopener noreferrer">Twitter</a>
                    <a href="https://www.instagram.com/ishavasya" target="_blank" rel="noopener noreferrer">Instagram</a>
                    <a href="https://youtube.com/@ishavasya" target="_blank" rel="noopener noreferrer">Youtube</a>
                </div>
            </div>
            <div className="top-pane">
                <br></br>
                <img src="assets/NikhilGaur_Pic.png" alt="NikhilGaur_Pic" onClick={openModal} onTouchEnd={openModal} className="profile-pic" />
                <h1>Nikhil Gaur</h1>
                <p>Interested in IT Systems, Music, History, Philosophy, Poetry, Medicine, Law, Linguistics and more. In no specific order.</p>
            </div>

            {/* Modal for enlarged image */}
            {isModalOpen && (
                <div className="modal" onClick={closeModal} onTouchEnd={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img
                            src="assets/NikhilGaur_Pic.png"
                            alt="NikhilGaur_Pic"
                            className="modal-image"
                        />
                    </div>
                </div>
            )}

            <div className="pane">
                <h1 className="pane-heading">Information Technology</h1>
                <p>I have been working as a software engineer for more than 5 years now.
                    Mostly using C#/.NET.
                </p>
            </div>
            <div className="pane">
                <h1 className="pane-heading">Music</h1>
                <p>Check out these Instagram highlights for Indian Classical Music.</p>
                <ul className="page-links">
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODg4NTg2OTIzMzA4NTUy?story_media_id=2588110169080045951_11392742604&igsh=ZGY0eXk5eHhtcWdp" target="_blank" rel="noopener noreferrer">Music Theory I</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDExODk2OTAzMzkyMDI4?story_media_id=2828763340140889447_11392742604&igsh=d2FoaDM0dTRzMDJs" target="_blank" rel="noopener noreferrer">Music Theory II</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODc1NTcyMjQ0NzY0ODgz?story_media_id=2354167933167742021_11392742604&igsh=NzZmOWU5b3gyNTd1" target="_blank" rel="noopener noreferrer">Music</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODQ3NTEyNzY1Njc2OTk2?story_media_id=2703346508484488286_11392742604&igsh=MTIwdjEzemc3Y3VhZA%3D%3D" target="_blank" rel="noopener noreferrer">Music II</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTc1OTQ2NTA1NzkyMzg2?story_media_id=2912798505292374009_11392742604&igsh=MXZxZTQ3cjNjMjg4MA%3D%3D" target="_blank" rel="noopener noreferrer">Music III</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MDExNTgzMjY1NTk2Mjg4?story_media_id=3040294843127190292_11392742604&igsh=Z2ZmMWUxNDcwNXBx" target="_blank" rel="noopener noreferrer">Music IV</a></li>
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MjM5OTU5NDQ3MjUwNjY4?story_media_id=3376574862196503705_11392742604&igsh=ZWh0bnQ0NXZjYTB0" target="_blank" rel="noopener noreferrer">Music V</a></li>
                </ul>
            </div>
            <div className="pane">
                <h1 className="pane-heading">Poems/Quotes</h1>
                <p>I have been writing quotes on various topics on these two Instagram pages.</p>
                <ul className="page-links">
                    <li><a href="https://www.instagram.com/your.sober.guy/" target="_blank" rel="noopener noreferrer">yourSoberGuy</a></li>
                    <li><a href="https://www.instagram.com/your.intoxicated.guy/" target="_blank" rel="noopener noreferrer">yourIntoxicatedGuy</a></li>
                </ul><br></br>
                <p>Also, check out this Instagram highlight on poetry.</p>
                <ul className="page-links">
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE4MTU3NjI0NDE0MDM2Njk1?story_media_id=2379964631249053964_11392742604&igsh=bHF4Y2tmNHJpaW0y" target="_blank" rel="noopener noreferrer">Poetry</a></li>
                </ul>
            </div>
            <div className="pane">
                <h1 className="pane-heading">Indian Philosophy</h1>
                <p>Check out this Instagram highlight for an informal introduction to Indian Philosophy.</p>
                <ul className="page-links">
                    <li><a href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3ODk2MTYyMjc2NDg3Mzg2?story_media_id=2298757387824626478_11392742604&igsh=ZXJmdjB4ZWJudHoy" target="_blank" rel="noopener noreferrer">bhāratīya darśana</a></li>
                </ul>
            </div>
            <div className="pane">
                <h1 className="pane-heading">Linguistics</h1>
                <p>Great tool for transliteration - <a className='page' href="https://www.learnsanskrit.org/tools/sanscript/" target="_blank" rel="noopener noreferrer">Sanscript</a></p>
                <p>To learn brahmic scripts, use this - <a href="https://www.aksharamukha.com/" target="_blank" rel="noopener noreferrer">Aksharamukha</a></p>
            </div>
            <div className="pane">
                <h1 className="pane-heading">Useful Resources</h1>

            </div>
            <div className="footer">
                <p>Copyright © Nikhil Gaur</p>
            </div>
        </div>
    );
}

export default Home;